import { createApp } from "vue";
import ToastPlugin from "vue-toast-notification";
import VueTheMask from "vue-the-mask";
import money from "v-money";

import { VDatePicker } from "vuetify/labs/VDatePicker";
import App from "./App.vue";

import router from "./router";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { Chart, registerables } from "chart.js";

import VueDatePicker from "@vuepic/vue-datepicker";
import StoragePlugin from "vue-web-storage";

import "@vuepic/vue-datepicker/dist/main.css";

import "vuetify/styles";
import "vue-toast-notification/dist/theme-sugar.css";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import "moment/locale/pt-br";
import "moment/dist/locale/pt-br";

import "@vuepic/vue-datepicker/dist/main.css";

Chart.register(...registerables);

const lightTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#3E94D1",
    secondary: "#344C9C",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const vuetify = createVuetify({
  components: { ...components, VDatePicker },
  directives,
  theme: {
    defaultTheme: "lightTheme",
    themes: {
      lightTheme,
    },
  },
});

const Vue = createApp(App);

Vue.component("VueDatePicker", VueDatePicker);

Vue.use(VueTheMask);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false,
});

Vue.use(vuetify);
Vue.use(router);
Vue.use(StoragePlugin);
Vue.use(ToastPlugin, { position: "top-right" });

Vue.mount("#app");
