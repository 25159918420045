import { createWebHistory, createRouter } from "vue-router";
import useAuth from "./services/useAuth";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("./views/Login.vue"),
  },

  {
    path: "/dash",
    component: () => import("./components/Dashboard"),
    beforeEnter(_, __, next) {
      if (useAuth.getUser() != null) {
        next();
        return;
      }

      next("/");
    },
    children: [
      {
        name: "Dashboard",
        path: "",
        component: () => import("./views/Home.vue"),
      },
      {
        name: "KPIs",
        path: "kpis",
        component: () => import("./views/KPIs.vue"),
      },
      {
        name: "Analise",
        path: "analise",
        component: () => import("./views/Analise.vue"),
      },
      {
        name: "MesaCredito",
        path: "mesa-credito",
        component: () => import("./views/MesaCredito.vue"),
        props: true,
      },

      {
        name: "MesaCreditoId",
        path: "mesa-credito/:id/:tipo",
        component: () => import("./views/MesaCredito.vue"),
      },

      {
        name: "Example",
        path: "example",
        component: () => import("./views/Example.vue"),
      },
      {
        name: "CriaEmpresa",
        path: "cria-empresa",
        component: () => import("./views/CriaEmpresa.vue"),
      },
      {
        name: "CriaUsuario",
        path: "cria-usuario",
        component: () => import("./views/CriaUsuario.vue"),
      },
      {
        name: "GestaoDeAcesso",
        path: "gestao-acesso",
        component: () => import("./views/GestaoDeAcesso.vue"),
      },
      {
        name: "Administrativo",
        path: "administrativo",
        component: () => import("./views/Administrativo.vue"),
        }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
